<template>

 <div class="companyInfo">

  <el-row>
    <h4 class="title"> COMPANY INFO </h4>
  </el-row>

  <el-row>
    <el-form :model="form" label-position="left" label-width="120px">

     <el-form-item label="Company Name">
       <el-col :span="5"><el-input v-model="form.companyName" prop="companyName"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="Address">
       <el-col :span="10"><el-input v-model="form.address" prop="address"></el-input></el-col>   
     </el-form-item>

      <el-form-item label="Telephone">
       <el-col :span="5"><el-input v-model="form.telephone" prop="telephone"></el-input></el-col>   
     </el-form-item>
  
   </el-form>
 </el-row>

  <el-row>
    <h4 class="title"> BANK INFO </h4>
  </el-row>

 <el-row>
    <el-form :model="form" label-position="left" label-width="120px">

     <el-form-item label="BANK NAME">
       <el-col :span="8"><el-input v-model="form.bankName" prop="bankName"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="BRANCH">
       <el-col :span="5"><el-input v-model="form.branch" prop="branch"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="BRANCH ADDR">
       <el-col :span="10"><el-input v-model="form.branchAdd" prop="branchAdd"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="SWIFT CODE">
       <el-col :span="5"><el-input v-model="form.swiftCode" prop="swiftCode"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="A/C NO">
       <el-col :span="5"><el-input v-model="form.acno" prop="acno"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="NAME">
       <el-col :span="5"><el-input v-model="form.acName" prop="acName"></el-input></el-col>   
     </el-form-item>

     <el-form-item label="NAME ADDS">
       <el-col :span="10"><el-input v-model="form.acNameAdd" prop="acNameAdd"></el-input></el-col>   
     </el-form-item>

    <el-form-item>
     <el-col :span="2"><el-button type="success" size="medium" plain round @click="modify">Modify</el-button></el-col>
    </el-form-item>
  
   </el-form>
 </el-row>

  </div>
</template>

<script>
import { axiosInstance } from '@/api'
export default {
    
  data() {
    return {

     form: {
     companyName:'',
     address:'',
     telephone:'',
     bankName:'',
     branch:'',
     branchAdd:'',
     swiftCode:'',
     acno:'',
     acName:'',
     acNameAdd:''
    },

    fullscreenLoading: false

   }
  },

  created(){
    this.loadData()
  },

methods: {
  loadData(){
    axiosInstance.get("/companyInfo").then((response) => {
    this.form = response.data[0];
    })
  },

  modify(){
    this.fullscreenLoading = true;  
    axiosInstance.put("/companyInfo/"+this.form.id,
    {companyName: this.form.companyName, address: this.form.address, telephone: this.form.telephone, bankName: this.form.bankName, 
    branch: this.form.branch, branchAdd: this.form.branchAdd, swiftCode: this.form.swiftCode, acno: this.form.acno, acName: this.form.acName,
    acNameAdd: this.form.acNameAdd}).then((response) => {
    this.loadData()

     this.$message({
       message: 'Success!!',
       type: 'success'
      })
    })
    
    .finally(()=>{
      this.fullscreenLoading = false;
    });
      
  }
   
 }
}

</script>

<style lang="scss" scoped>
.companyInfo{
  .el-row {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  };

  .title {color:#409EFF}
 
}

</style>